import { useRef, useState } from 'react';
import { isClientSide } from 'services/utils';

interface ObserverHook {
    observer: ResizeObserver;
    width: number;
}

export const useResizeObserver = (): ObserverHook => {
    const [width, setWidth] = useState<number>(0);

    const observer = useRef(
        isClientSide()
            ? new ResizeObserver((entries: ResizeObserverEntry[]) => {
                  const size = getObservedWidth(entries);
                  setWidth(size);
              })
            : null
    );

    return { observer: observer?.current, width };
};

const getObservedWidth = (entries: ResizeObserverEntry[]) => {
    let size = 0;
    if (entries.length > 0) {
        const entry = entries[0];
        if (entry.contentBoxSize) {
            // Firefox implements `contentBoxSize` as a single content rect, rather than an array
            const contentBoxSize = Array.isArray(entry.contentBoxSize)
                ? entry.contentBoxSize[0]
                : entry.contentBoxSize;
            size = contentBoxSize.inlineSize;
        } else {
            size = entry.contentRect.width;
        }
    }
    return size;
};
