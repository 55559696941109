import { AccessTime, PersonPinCircleOutlined } from '@mui/icons-material';
import { Box, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import Render from 'components/atoms/Render';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import { MediumText, NormalText, SmallText } from 'components/atoms/Typography';
import { useDataTranslator } from 'hooks/useDataTranslator';
import { LocaleStrings, Restaurant } from 'klikni-jadi-shared-stuff';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { getVariant } from 'services/typography';
import FavoriteToggle from '../FavoriteToggle';

interface IMapCardProps {
    focused?: false;
    image: string;
    logo: string;
    name: string;
    cuisine: LocaleStrings;
    orderType: string;
    id?: string;
    property?: Restaurant;
    hideFavoriteToggle?: boolean;
}

const MapCard = ({
    logo,
    name,
    cuisine,
    hideFavoriteToggle = false,
    property,
}: // rating,
// orderType,
IMapCardProps) => {
    const { t } = useTranslation('common');
    const { translate } = useDataTranslator();
    const router = useRouter();
    const nameRef = useRef<HTMLSpanElement>();
    const nameContainerRef = useRef<HTMLDivElement>();
    const [hasTooltip, setHasTooltip] = useState<boolean>(false);
    const theme = useTheme();

    useEffect(() => {
        if (nameRef.current && nameContainerRef.current) {
            if (nameRef.current.offsetWidth + 20 > nameContainerRef.current.offsetWidth) {
                setHasTooltip(true);
            } else setHasTooltip(false);
        }
    }, []);

    return (
        <>
            <Paper
                elevation={5}
                sx={{
                    height: '100px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    '&:hover': {
                        transform: { xs: 'none', md: 'scale(1.05)' },
                    },
                    transition: theme.transitions.create(['transform'], {
                        duration: theme.transitions.duration.short,
                        easing: theme.transitions.easing.easeInOut,
                    }),
                    backgroundColor: 'white',
                    paddingBottom: '10px',
                }}
            >
                <Box display="flex" flexDirection="row" height="100%" pb={1}>
                    <ResponsiveImage
                        src={logo}
                        style={{
                            width: `100px`,
                            height: `100px`,
                        }}
                        options={{
                            width: 150,
                            height: 150,
                        }}
                        crop
                    />
                    <Box flex={1} display="flex" flexDirection="column" justifyContent="space-between">
                        <Box px={1} pb={1} pt={0}>
                            <Box>
                                <Box
                                    sx={{
                                        width: '100%',
                                        pt: 1.5,
                                    }}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    color="black"
                                    // fontSize={{ xs: '1.3em', md: '1em' }}
                                >
                                    <Tooltip
                                        title={
                                            hasTooltip ? (
                                                <MediumText
                                                    onClick={event => {
                                                        event.stopPropagation();
                                                    }}
                                                >
                                                    {name}
                                                </MediumText>
                                            ) : (
                                                ''
                                            )
                                        }
                                        placement="top"
                                        arrow
                                    >
                                        <Box
                                            pl={1}
                                            pr={0.5}
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                            ref={nameContainerRef}
                                        >
                                            <Typography
                                                component="span"
                                                variant={getVariant({ normal: true })}
                                                sx={{ lineHeight: 1.2, fontWeight: 500 }}
                                                ref={nameRef}
                                            >
                                                {name}
                                            </Typography>
                                        </Box>
                                    </Tooltip>
                                    {!hideFavoriteToggle && (
                                        <Box pr={2} flex={1}>
                                            <FavoriteToggle restaurantId={property?.id} />
                                        </Box>
                                    )}
                                </Box>
                                <Box>
                                    <Box
                                        sx={{
                                            color: '#6d7783',
                                            fontSize: '0.8rem',
                                            lineHeight: 1.2,
                                        }}
                                        pl={1}
                                    >
                                        <NormalText>
                                            {cuisine && (
                                                <span>
                                                    {translate(cuisine)} {t('cuisine', { count: 1 })} &nbsp;
                                                </span>
                                            )}{' '}
                                        </NormalText>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box px={2} display="flex" color="#6D7783">
                            {/* <SmallText>{cuisine}</SmallText> */}
                            <Render when={!router.query?.type || router.query.type === 'delivery'}>
                                <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                                    <AccessTime />
                                    <Box ml={0.5}>30 - 40 {t('min')}</Box>
                                </SmallText>
                            </Render>
                            <Render when={router.query?.type === 'takeout'}>
                                <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                                    <PersonPinCircleOutlined />
                                    <Box ml={0.5}>{property?.distance} km</Box>
                                </SmallText>
                            </Render>
                            {/* <SmallText sx={{ mx: 1 }}>&#x2022;</SmallText>
                            <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                                <SentimentSatisfiedAlt />
                                <Box ml={0.5}>9.2</Box>
                            </SmallText> */}
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </>
    );
};

export default MapCard;
