import { PersonPinCircleOutlined, SentimentSatisfiedAlt } from '@mui/icons-material';
import { Box, Grid, Hidden, Paper, useTheme } from '@mui/material';
import Render from 'components/atoms/Render';
import { LargeText, NormalText, SmallText } from 'components/atoms/Typography';
import { useRestaurantOpenCloseLabels } from 'hooks/useRestaurantOpenCloseLabels';
import { LocaleStrings, Restaurant } from 'klikni-jadi-shared-stuff';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import FavoriteToggle from '../FavoriteToggle';
import RedClockIcon from 'assets/svg/RedClockIcon.svg';
import RedBurgerIcon from 'assets/svg/RedBurgerIcon.svg';
import LocationIcon from 'assets/svg/LocationIcon.svg';
import DotBetween from 'assets/svg/dotBetween.svg';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import { useDataTranslator } from 'hooks/useDataTranslator';

interface IListCardProps {
    focused?: false;
    image: string;
    logo: string;
    name: string;
    cuisine: LocaleStrings;
    orderType: string;
    id?: string;
    property?: Restaurant;
}

const ListCard = ({ focused, logo, name, cuisine, property }: IListCardProps) => {
    const { message, isOpenNow } = useRestaurantOpenCloseLabels(property?.workingHours);
    const theme = useTheme();

    const { t } = useTranslation('common');
    const { translate } = useDataTranslator();
    return (
        <Paper
            sx={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                position: 'relative',
                borderRadius: '10px',
                // boxShadow: '0 10px 9px 0 rgba(85, 141, 202, 0.1)',
                '&:hover': {
                    // boxShadow: '0 10px 9px 0 rgba(85, 141, 202, 0.1)',
                    transform: { xs: 'none', md: 'scale(1.02)' },
                },
                transition: theme.transitions.create(['transform'], {
                    duration: theme.transitions.duration.short,
                    easing: theme.transitions.easing.easeInOut,
                }),
            }}
            elevation={5}
        >
            <Box
                style={{
                    backgroundColor: focused ? 'rgba(183, 222, 224, 0.25)' : 'white',
                }}
                display="flex"
                flexDirection="column"
                // pb={{ md: 1 }}
                sx={{
                    width: '100%',
                    backgroundColor: '#f9f9f9',

                    // height: { xs: '100%', lg: '120px' },
                    height: 150,
                }}
            >
                <Box display="flex" flexDirection="row" flex={1} height="100%">
                    <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                        {/* <Box width={150} height={150}>
                            <KlikImage
                                src={logo}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            ></KlikImage>
                        </Box> */}
                        <ResponsiveImage
                            src={logo}
                            options={{
                                height: 150,
                                width: 150,
                            }}
                        />
                    </Box>
                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <Box>
                            <img
                                src={logo}
                                style={{
                                    width: '80px',
                                    height: '80px',
                                }}
                            />
                        </Box>
                    </Box>
                    <Grid display="flex" flex={1} xs={12} flexWrap="wrap" flexDirection="row">
                        <Grid item flex={{ lg: 1 }} xs={7}>
                            <Box p={2} display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                                <Box flexDirection="column">
                                    <Box>
                                        <Box display="flex" alignItems="center" style={{ fontSize: '0.8rem' }}>
                                            <LargeText semibold>{name}</LargeText>
                                            <LargeText semibold pl={1}>
                                                <img src={DotBetween} style={{ paddingBottom: '5px' }} />
                                            </LargeText>
                                            <Box pl={1}>
                                                <FavoriteToggle restaurantId={property.id} />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box
                                            sx={{
                                                color: '#6d7783',
                                                fontSize: '0.9rem',
                                                lineHeight: 1.6,
                                                marginLeft: '3px',
                                            }}
                                        >
                                            <NormalText>
                                                {cuisine && (
                                                    <span>
                                                        {translate(cuisine)} {t('cuisine', { count: 1 })} &nbsp;
                                                    </span>
                                                )}{' '}
                                            </NormalText>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                    {property?.minOrderAmount ? (
                                        <Box
                                            sx={{
                                                color: '#6d7783',
                                                fontSize: '0.9rem',
                                                lineHeight: 1.6,
                                                marginLeft: '3px',
                                            }}
                                        >
                                            <NormalText sx={{ display: 'flex', alignItems: 'center' }}>
                                                <PersonPinCircleOutlined />
                                                {property?.address?.fullAddress}
                                                <img src={DotBetween} style={{ marginLeft: '5px' }} />
                                                <SentimentSatisfiedAlt style={{ marginLeft: '5px' }} />
                                                <Box ml={1}>{property?.avgRating}</Box>
                                            </NormalText>
                                        </Box>
                                    ) : (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: '#6d7783',
                                                fontSize: '0.9rem',
                                                lineHeight: 1.6,
                                                marginLeft: '3px',
                                            }}
                                        >
                                            <Box>30-40 min</Box>
                                            <img src={DotBetween} style={{ marginLeft: '5px', marginRight: '5px' }} />
                                            <SentimentSatisfiedAlt />
                                            &nbsp;
                                            <Box>{property?.avgRating}</Box>
                                            <img src={DotBetween} style={{ marginLeft: '5px', marginRight: '5px' }} />
                                            <Box>{t('minOrderAmount')}:&nbsp;</Box>
                                            <Box>
                                                {property.minOrderAmount ?? 400} {t('mkd')}.
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                            <Grid item xs={5} display="flex" sx={{ alignItems: 'center' }}>
                                {!property?.minOrderAmount && (
                                    <Grid display="flex" xs={6}>
                                        <Box sx={{ paddingRight: '5px' }}>
                                            <img src={LocationIcon} />
                                        </Box>
                                        <Box>
                                            <Box>{t('location', { count: 1 })}:</Box>
                                            <Box>{property?.address?.fullAddress}</Box>
                                            <Box>{property?.address?.city}</Box>
                                        </Box>
                                    </Grid>
                                )}
                                <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                                    <Grid item xs={6} display="flex">
                                        <Box sx={{ paddingRight: '5px' }}>
                                            <img src={RedClockIcon} />
                                        </Box>
                                        <Box>
                                            {t('hoursOfOperation')}
                                            <Box>
                                                <Render when={isOpenNow}>
                                                    <SmallText
                                                        sx={{
                                                            color: '#19A446',
                                                        }}
                                                    >
                                                        {t('openNow')}
                                                    </SmallText>
                                                </Render>
                                                <Render when={!isOpenNow}>
                                                    <SmallText color="primary">{t('closedNow')}</SmallText>
                                                </Render>
                                                <Box>
                                                    {isOpenNow ? (
                                                        <Box>
                                                            {t('daily')} {'  '}
                                                            {property?.workingHours?.booking?.mon[0]?.from
                                                                ?.toString()
                                                                ?.replace(/(..)$/, ':$1')}{' '}
                                                            -
                                                            {property?.workingHours?.booking?.mon[0]?.to
                                                                ?.toString()
                                                                ?.replace(/(..)$/, ':$1')}
                                                        </Box>
                                                    ) : (
                                                        <>
                                                            <SmallText>{message}</SmallText>
                                                        </>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Box>
                                {property?.minOrderAmount && (
                                    <Grid display="flex">
                                        <Box sx={{ paddingRight: '5px' }}>
                                            <img src={RedBurgerIcon} />
                                        </Box>
                                        <Box>
                                            <Box>30-40 min</Box>
                                            <Box>{t('minOrderAmount')}:</Box>
                                            <Box>
                                                {property.minOrderAmount} {t('mkd')}.
                                            </Box>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </Grid>
                </Box>
            </Box>
        </Paper>
    );
};

export default ListCard;
