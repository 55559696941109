import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { LocaleStrings, Restaurant, RestaurantFilter } from 'klikni-jadi-shared-stuff';
import GridCard from './cards/GridCard';
import ListCard from './cards/ListCard';
import MapCard from './cards/MapCard';
import MapInfoCard from './cards/MapInfoCard';
import { Timestamp } from '@firebase/firestore-types';

interface IPropertyCardProps {
    image?: string;
    logo?: string;
    name?: string;
    cuisines?: RestaurantFilter[];
    price?: number;
    orderType?: string;
    view?: 'list' | 'grid' | 'map' | 'map-info';
    focused?: boolean;
    parentWidth?: number;
    index?: number;
    autoWidthFromParent?: boolean;
    hidePropertyInfo?: boolean;
    fullWidth?: boolean;
    id?: string;
    hideFavoriteToggle?: boolean;
    isClosed?: boolean;
    property?: Restaurant;
    setNumElements?: (n: number) => void;
    isPaused?: boolean;
    onCardSized?: (width?: number) => void;
}

const PropertyCard = ({
    image,
    logo,
    name,
    cuisines,
    orderType,
    view = 'list',
    parentWidth,
    index,
    autoWidthFromParent = false,
    hidePropertyInfo = false,
    fullWidth,
    id,
    hideFavoriteToggle = false,
    isClosed,
    property,
    setNumElements: _setNumElements,
    isPaused,
    onCardSized,
}: IPropertyCardProps) => {
    const settingsFilters = useSelector((state: RootState) => state.settingsFilters.data);
    const [cuisineName, setCuisineName] = useState<LocaleStrings>({ en: '', mk: '' });
    const theme = useTheme();
    const matchedMedium = useMediaQuery(theme.breakpoints.down('lg'));
    const matched600 = useMediaQuery('(max-width: 600px)');
    const matched500 = useMediaQuery('(max-width: 500px)');
    const matched400 = useMediaQuery('(max-width: 400px)');
    const matched300 = useMediaQuery('(max-width: 300px)');

    const [elementWidth, setElementWidth] = useState<number>(0);
    const [numElements, setNumElements] = useState<number>(1);

    const calculatedSize = useMemo(() => {
        if (matched300) return 400;
        if (matched400) return 400;
        if (matched500) return 400;
        if (matched600) return 400;
        if (matchedMedium) return 400;
        return 400;
    }, [matchedMedium, matched500, matched600, matched400, matched300]);

    useEffect(() => {
        const _numElements = Math.ceil(parentWidth / calculatedSize);
        if (_numElements > 0) {
            const spacingArea = (_numElements - 1) * 40;
            setElementWidth((parentWidth - spacingArea) / _numElements);
            onCardSized?.((parentWidth - spacingArea) / _numElements);
            setNumElements(_numElements);
            if (_setNumElements) _setNumElements(_numElements);
        }
    }, [parentWidth, calculatedSize]);

    useEffect(() => {
        if (settingsFilters?.cuisines) {
            const priorityCuisine = cuisines?.find(cuisine => cuisine.priority === 1);
            setCuisineName(settingsFilters.cuisines.find(c => c.id === priorityCuisine?.id)?.name);
        }
    }, [settingsFilters]);

    return (
        <>
            {view === 'list' ? (
                <ListCard
                    property={property}
                    id={id}
                    image={image}
                    logo={logo}
                    name={name}
                    cuisine={cuisineName}
                    orderType={orderType}
                />
            ) : view === 'grid' ? (
                <GridCard
                    image={image}
                    logo={logo}
                    name={name}
                    cuisine={cuisineName}
                    orderType={orderType}
                    index={index}
                    numElements={numElements}
                    elementWidth={elementWidth}
                    autoWidth={autoWidthFromParent}
                    hidePropertyInfo={hidePropertyInfo}
                    fullWidth={fullWidth}
                    id={id}
                    hideFavoriteToggle={hideFavoriteToggle}
                    isClosed={isClosed}
                    property={property}
                    isPaused={isPaused}
                />
            ) : view === 'map' ? (
                <MapCard
                    image={image}
                    logo={logo}
                    name={name}
                    cuisine={cuisineName}
                    orderType={orderType}
                    id={id}
                    property={property}
                />
            ) : (
                <MapInfoCard image={image} logo={logo} name={name} property={property} />
            )}
        </>
    );
};

export { GridCard, ListCard, MapCard };

export default PropertyCard;
