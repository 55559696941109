import { Box } from '@mui/material';
import favoritesIconSelected from 'assets/svg/favorites-icon-blue-active.svg';
import favoritesIcon from 'assets/svg/favorites-icon-blue.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { useFirestore } from 'hooks/useFirestore';
import { MouseEvent, useEffect, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useAuth } from 'context/authentication';
import React from 'react';
import { currentUserFirestoreActions } from 'redux/actions/firestore/users';

interface IFavoriteToggleProps {
    restaurantId?: string;
}

const FavoriteToggle = ({ restaurantId }: IFavoriteToggleProps) => {
    const currentUser = useSelector((state: RootState) => state.currentUser);
    const firestore = useFirestore('customers');
    const { t } = useTranslation('common');
    const { isAuthenticated } = useAuth();

    const isSelected = useMemo(() => {
        return currentUser?.data?.favorites?.includes(restaurantId);
    }, [currentUser.version, restaurantId, t]);

    useEffect(() => {
        if (isAuthenticated) firestore.doc(currentUser?.data?.id, currentUserFirestoreActions, { listen: true });
    }, []);

    if (!isAuthenticated) {
        return null;
    }

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        let newFavorites = currentUser.data.favorites || [];
        if (currentUser.data.favorites?.includes(restaurantId)) {
            newFavorites = currentUser.data.favorites.filter(f => f !== restaurantId);
        } else {
            newFavorites.push(restaurantId);
        }
        firestore.update(currentUser.data.id, { favorites: newFavorites });
    };

    return (
        <Box
            sx={{
                cursor: 'pointer',
                display: 'flex',
            }}
            onClick={handleClick}
        >
            {(isSelected && (
                <Box
                    component="img"
                    src={favoritesIconSelected}
                    sx={{
                        height: '100%',
                    }}
                />
            )) || (
                <Box
                    component="img"
                    src={favoritesIcon}
                    sx={{
                        height: '100%',
                    }}
                />
            )}
        </Box>
    );
};

export default React.memo(FavoriteToggle);
