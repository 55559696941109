import { AccessTime, PersonPinCircleOutlined, SentimentSatisfiedAlt } from '@mui/icons-material';
import { Box, Divider, Paper, Tooltip, Typography } from '@mui/material';
import Render from 'components/atoms/Render';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import { MediumText, SmallText } from 'components/atoms/Typography';
import { useRestaurantOpenCloseLabels } from 'hooks/useRestaurantOpenCloseLabels';
import { Restaurant } from 'klikni-jadi-shared-stuff';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { getVariant } from 'services/typography';

interface IMapInfoCardProps {
    focused?: false;
    image: string;
    logo: string;
    name: string;
    property: Restaurant;
}

const MapInfoCard = ({ property, image, logo, name }: IMapInfoCardProps) => {
    const router = useRouter();
    const { t } = useTranslation('common');
    const nameRef = useRef<HTMLSpanElement>();
    const nameContainerRef = useRef<HTMLDivElement>();
    const [hasTooltip, setHasTooltip] = useState<boolean>(false);
    const { message, isOpenNow } = useRestaurantOpenCloseLabels(property?.workingHours);

    useEffect(() => {
        if (nameRef.current && nameContainerRef.current) {
            if (nameRef.current.offsetWidth + 20 > nameContainerRef.current.offsetWidth) {
                setHasTooltip(true);
            } else setHasTooltip(false);
        }
    }, []);

    return (
        <>
            <Paper
                sx={{
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    pb: 1.5,
                    position: 'relative',
                    borderRadius: '10px',
                }}
            >
                <Box display="flex" flexDirection="column" flex={1} sx={{ width: '250px' }}>
                    <Box
                        sx={{
                            position: 'relative',
                            height: 150,
                        }}
                    >
                        {/* <img
                            src={backgroundShadow}
                            style={{
                                width: `${fullWidth ? '100%' : autoWidth ? `${elementWidth}px` : '220px'}`,
                                height: `${autoWidth ? `${elementWidth}px` : '220px'}`,
                                position: 'absolute',
                                left: 0,
                                top: 0,
                            }}
                        /> */}
                        <ResponsiveImage
                            src={image}
                            style={{
                                width: '250px',
                                // height: `${autoWidth ? `${elementWidth}px` : '220px'}`,
                                height: 150,
                                maxHeight: '250px',
                                objectFit: 'cover',
                                borderRadius: '10px 10px 0 0',
                            }}
                            options={{
                                width: 250,
                                height: 150,
                            }}
                            crop
                        />
                        <Box position="absolute" bottom={0} right={0} height={60}>
                            <ResponsiveImage
                                src={logo}
                                style={{
                                    width: `60px`,
                                    height: `60px`,
                                    borderRadius: '15px 0 0 0',
                                }}
                                options={{
                                    width: 100,
                                    height: 100,
                                }}
                                crop
                            />
                        </Box>
                    </Box>
                    <Divider />
                    <Box flex={1}>
                        <Box
                            sx={{
                                width: '100%',
                                pt: 0.8,
                            }}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            color="black"
                            // fontSize={{ xs: '1.3em', md: '1em' }}
                        >
                            <Tooltip
                                title={
                                    hasTooltip ? (
                                        <MediumText
                                            onClick={event => {
                                                event.stopPropagation();
                                            }}
                                        >
                                            {name}
                                        </MediumText>
                                    ) : (
                                        ''
                                    )
                                }
                                placement="top"
                                arrow
                            >
                                <Box
                                    pl={1}
                                    pr={0.5}
                                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                    ref={nameContainerRef}
                                >
                                    <Typography
                                        component="span"
                                        variant={getVariant({ normal: true })}
                                        sx={{ lineHeight: 1.2, fontWeight: 500 }}
                                        ref={nameRef}
                                    >
                                        {name}
                                    </Typography>
                                </Box>
                            </Tooltip>
                        </Box>
                        <Box ml={1}>
                            <Render when={isOpenNow}>
                                <SmallText
                                    sx={{
                                        color: '#19A446',
                                    }}
                                >
                                    {t('openNow')}
                                </SmallText>
                            </Render>
                            <Render when={!isOpenNow}>
                                <SmallText color="primary">{t('closedNow')}</SmallText>
                            </Render>
                            <SmallText sx={{ mx: 1 }}>-</SmallText>
                            <SmallText>{message}</SmallText>
                        </Box>
                        <Box px={1} display="flex" color="#6D7783" style={{ width: '220px' }}>
                            {/* <SmallText>{cuisine}</SmallText> */}
                            <Render when={!router.query?.type || router.query.type === 'delivery'}>
                                <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                                    <AccessTime />
                                    <Box ml={0.5}>30 - 40 {t('min')}</Box>
                                </SmallText>
                            </Render>
                            <Render when={router.query?.type === 'takeout'}>
                                <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                                    <PersonPinCircleOutlined />
                                    <Box ml={0.5}>{property?.distance} km</Box>
                                </SmallText>
                            </Render>
                            <SmallText sx={{ mx: 1 }}>&#x2022;</SmallText>
                            <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                                <SentimentSatisfiedAlt />
                                <Box ml={0.5}>9.2</Box>
                            </SmallText>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </>
    );
};

export default MapInfoCard;
