import { Box, Divider, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import { MediumText, NormalText, SmallText } from 'components/atoms/Typography';
import FavoriteToggle from '../FavoriteToggle';
import { PersonPinCircleOutlined } from '@mui/icons-material';
import { useRouter } from 'next/router';
import Render from 'components/atoms/Render';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useRef, useState } from 'react';
import { getVariant } from 'services/typography';
import { LocaleStrings, Restaurant } from 'klikni-jadi-shared-stuff';
import { useRestaurantOpenCloseLabels } from 'hooks/useRestaurantOpenCloseLabels';
import { upperCase } from 'lodash';
import { days } from 'containers/details/DetailsContent';
import { useDataTranslator } from 'hooks/useDataTranslator';

interface IGridCardProps {
    focused?: false;
    image: string;
    logo: string;
    name: string;
    cuisine: LocaleStrings;
    orderType: string;
    numElements: number;
    elementWidth: number;
    index: number;
    autoWidth: boolean;
    hidePropertyInfo?: boolean;
    fullWidth?: boolean;
    id?: string;
    hideFavoriteToggle?: boolean;
    isClosed?: boolean;
    property?: Restaurant;
    isPaused?: boolean;
}

const GridCard = ({
    focused,
    image,
    logo,
    name,
    cuisine,
    index,
    elementWidth,
    numElements,
    autoWidth,
    fullWidth,
    id,
    hideFavoriteToggle = false,
    property,
    isPaused = true,
}: IGridCardProps) => {
    const [pausedUntilString, setPausedUntilString] = useState<string>('');

    const router = useRouter();
    const { t } = useTranslation('common');
    const { translate } = useDataTranslator();
    const nameRef = useRef<HTMLSpanElement>();
    const nameContainerRef = useRef<HTMLDivElement>();
    const [hasTooltip, setHasTooltip] = useState<boolean>(false);
    const { message, isOpenNow } = useRestaurantOpenCloseLabels(property?.workingHours);
    const theme = useTheme();

    useEffect(() => {
        if (nameRef.current && nameContainerRef.current) {
            if (nameRef.current.offsetWidth + 20 > nameContainerRef.current.offsetWidth) {
                setHasTooltip(true);
            } else setHasTooltip(false);
        }
    }, [elementWidth]);

    useEffect(() => {
        if (property?.pausedUntil) {
            const hours: string =
                new Date(property.pausedUntil?.['_seconds'] * 1000).getHours().toString() ||
                new Date(property.pausedUntil?.seconds * 1000).getHours().toString();
            const minutes: string =
                new Date(property.pausedUntil?.['_seconds'] * 1000).getMinutes().toString() ||
                new Date(property.pausedUntil?.seconds * 1000).getMinutes().toString();
            const timestampToString: string = hours + minutes;
            if (
                parseInt(property?.workingHours?.delivery?.[days[new Date().getDay()]?.day]?.[0]?.to) <
                parseInt(timestampToString)
            ) {
                console.log(
                    'property?.workingHours?.delivery?.[days[new Date().getDay()]?.day]?.[0]?.from',
                    property?.workingHours?.delivery?.[days[new Date().getDay()]?.day]?.[0]?.from
                );
                setPausedUntilString(property?.workingHours?.delivery?.[days[new Date().getDay()]?.day]?.[0]?.from);
            } else {
                setPausedUntilString(timestampToString);
            }
        }
    }, [property]);

    if (autoWidth && elementWidth === 0) return null;

    return (
        <>
            <Paper
                sx={{
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    width: fullWidth && '100%',
                    pb: 1.5,
                    position: 'relative',
                    borderRadius: '10px',
                    mb: 5,
                    height: '250px',
                    '&:hover': {
                        transform: 'scale(1.05)',
                    },
                    transition: theme.transitions.create(['transform'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shorter,
                    }),
                    border: `1px ${theme.palette.grey[500]} solid`,
                    // ...((autoWidth ? (index + 1) % numElements !== 0 : false) ? { marginRight: '40px' } : {}),
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    flex={1}
                    sx={{ width: `${fullWidth ? '100%' : autoWidth ? `${elementWidth - 2}px` : '250px'}` }}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            height: 170,
                        }}
                    >
                        {/* <img
                            src={backgroundShadow}
                            style={{
                                width: `${fullWidth ? '100%' : autoWidth ? `${elementWidth}px` : '220px'}`,
                                height: `${autoWidth ? `${elementWidth}px` : '220px'}`,
                                position: 'absolute',
                                left: 0,
                                top: 0,
                            }}
                        /> */}
                        <ResponsiveImage
                            src={image}
                            style={{
                                width: `${fullWidth ? '100%' : autoWidth ? `${elementWidth - 2}px` : '250px'}`,
                                // height: `${autoWidth ? `${elementWidth}px` : '220px'}`,
                                height: 170,
                                maxHeight: '250px',
                                objectFit: 'cover',
                                borderRadius: '10px 10px 0 0',
                            }}
                            options={{
                                width: 1000,
                                height: 600,
                            }}
                            crop
                            alt="restaurant thumbnail"
                        />
                        {logo && (
                            <Box position="absolute" bottom={0} right={0} height={60}>
                                <ResponsiveImage
                                    src={logo}
                                    style={{
                                        width: `60px`,
                                        height: `60px`,
                                        borderRadius: '15px 0 0 0',
                                    }}
                                    options={{
                                        width: 100,
                                        height: 100,
                                    }}
                                    crop
                                    alt="restaurant logo"
                                />
                            </Box>
                        )}
                        <Render when={!isOpenNow}>
                            <Box
                                position="absolute"
                                height="100%"
                                width="100%"
                                top={0}
                                sx={{
                                    backgroundColor: 'rgba(0,0,0,0.7)',
                                }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="10px 10px 0 0"
                            >
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <Box>
                                        <NormalText semibold sx={{ color: 'white' }}>
                                            {upperCase(t('closed'))}
                                        </NormalText>
                                    </Box>
                                    <Box>
                                        <SmallText sx={{ color: 'white' }}>{message}</SmallText>
                                    </Box>
                                </Box>
                            </Box>
                        </Render>
                        <Render when={!isPaused}>
                            <Box
                                position="absolute"
                                height="100%"
                                width="100%"
                                top={0}
                                sx={{
                                    backgroundColor: 'rgba(0,0,0,0.7)',
                                }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="10px 10px 0 0"
                            >
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <Box>
                                        <NormalText semibold sx={{ color: 'white' }}>
                                            {upperCase(t('paused'))}
                                        </NormalText>
                                    </Box>
                                    <Box>
                                        <SmallText sx={{ color: 'white' }}>
                                            {upperCase(t('opensAt'))} {pausedUntilString.replace(/(..)$/, ':$1')}
                                        </SmallText>
                                    </Box>
                                </Box>
                            </Box>
                        </Render>
                    </Box>
                    <Divider />
                    <Box flex={1}>
                        <Box
                            sx={{
                                width: '100%',
                                pt: 0.8,
                            }}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            color="black"
                            // fontSize={{ xs: '1.3em', md: '1em' }}
                        >
                            <Tooltip
                                title={
                                    hasTooltip ? (
                                        <MediumText
                                            onClick={event => {
                                                event.stopPropagation();
                                            }}
                                        >
                                            {name}
                                        </MediumText>
                                    ) : (
                                        ''
                                    )
                                }
                                placement="top"
                                arrow
                            >
                                <Box
                                    pl={1}
                                    pr={0.5}
                                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                    ref={nameContainerRef}
                                >
                                    <Typography
                                        component="span"
                                        variant={getVariant({ normal: true })}
                                        sx={{ lineHeight: 1.2, fontWeight: 500 }}
                                        ref={nameRef}
                                    >
                                        {name}
                                    </Typography>
                                </Box>
                            </Tooltip>
                            {!hideFavoriteToggle && (
                                <Box pr={2}>
                                    <FavoriteToggle restaurantId={id} />
                                </Box>
                            )}
                        </Box>
                        {cuisine && (
                            <Box ml={1}>
                                <SmallText>
                                    {translate(cuisine)} {t('cuisine', { count: 1 }).toLowerCase()}
                                </SmallText>
                            </Box>
                        )}
                        {/* <Box ml={1}> */}
                        {/* <Render when={isOpenNow}>
                                <SmallText
                                    sx={{
                                        color: 'primary.main',
                                    }}
                                >
                                    {t('openNow')}
                                </SmallText>
                            </Render> */}
                        {/* <Render when={!isOpenNow}>
                                <SmallText color="primary">{t('closedNow')}</SmallText>
                            </Render> */}
                        {/* <Render when={isOpenNow}>
                                <SmallText sx={{ mx: 1 }}>-</SmallText>
                                <SmallText>{message}</SmallText>
                            </Render> */}
                        {/* </Box> */}
                        {property?.distance && (
                            <Box
                                px={1}
                                display="flex"
                                color="#6D7783"
                                style={{ width: `${fullWidth ? '100%' : autoWidth ? `${elementWidth}px` : '250px'}` }}
                            >
                                {/* <SmallText>{cuisine}</SmallText> */}
                                <Render when={!router.query?.type || router.query.type === 'delivery'}>
                                    <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                                        <PersonPinCircleOutlined />
                                        <Box ml={0.5}>{property?.distance} km</Box>
                                    </SmallText>
                                </Render>
                                <Render when={router.query?.type === 'takeout'}>
                                    <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                                        <PersonPinCircleOutlined />
                                        <Box ml={0.5}>{property?.distance} km</Box>
                                    </SmallText>
                                </Render>
                                {/* <SmallText sx={{ mx: 1 }}>&#x2022;</SmallText> */}
                                {/* <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                                <SentimentSatisfiedAlt />
                                <Box ml={0.5}>9.2</Box>
                            </SmallText> */}
                            </Box>
                        )}
                    </Box>
                </Box>

                {/* <ThumbnailTags tags={{ sponsored: property?.sections?.isSponsored, new: property?.status.isNew }} /> */}
            </Paper>
        </>
    );
};

export default GridCard;
